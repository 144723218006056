export default [
  {
    key: 'customer',
    sortable: false,
    label: 'Customer',

  },
  {
    key: 'display_name_as',
    sortable: false,
    label: 'Display name as',
  },
  {
    key: 'mobile',
    sortable: false,
    label: 'Mobile',
  },
  {
    key: 'phone',
    sortable: false,
    label: 'Phone',
  },

  {
    key: 'owner',
    sortable: false,
    label: 'Owner',
  },
  {
    key: 'created_by',
    sortable: false,
    label: 'Created By',
  },
  {
    key: 'updated_by',
    sortable: false,
    label: 'Updated By',
  },

  {
    key: 'actions',
    sortable: false,
    label: 'ACTIONS',
  },

]
