<template>
  <b-modal
    ref="modal"
    v-model="onControl"
    centered
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    title="New Term"
    @hidden="close"
  >
    <validation-observer ref="form">
      <validation-provider
        v-slot="{ errors, valid }"
        name="name"
        rules="required"
      >
        <b-form-group
          label="Name"
        >
          <b-form-input
            v-model="term.name"
          />
          <span
            v-if="errors[0]"
            class="text-danger"
          >Name {{ errors[0] }}</span>
        </b-form-group>
        <div>
          <b-form-radio v-model="term.type_term">
            Due in fixed number of days
          </b-form-radio>
          <div style="display: grid; grid-template-columns: 4fr 2fr 3fr; align-items: center; margin-top: 0.4rem">
            <b-form-input
              v-model="term.days"
              :disabled="term.type_term === 2"
            /> <span>&nbsp;&nbsp; Days</span>
          </div>
        </div>
        <div style="margin-top: 1rem">
          <b-form-radio v-model="term.type_term">
            Due by certain day of the month
          </b-form-radio>
          <div style="display: grid; grid-template-columns: 4fr 2fr 3fr; align-items: center; margin-top: 0.4rem">
            <b-form-input
              v-model="term.day_of_month"
              :disabled="term.type_term === 1"
            /> <span>&nbsp;&nbsp;  days of month</span>
          </div>
        </div>
        <div class="mt-1">
          <span>Due the next month if issued within</span>
          <div style="display: grid; grid-template-columns: 4fr 3fr 2fr; align-items: center; margin-top: 0.4rem">
            <b-form-input
              v-model="term.days_of_due_date"
              :disabled="term.type_term === 1"
            /> <span>&nbsp;&nbsp;  days of due date</span>
          </div>
        </div>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <button-save @click="saveTerm"/>
    </template>
  </b-modal>
</template>

<script>
import ButtonSave from '@/views/commons/utilities/ButtonSave.vue'
import CompaniesService from "@/views/management/views/companies/companies.service";
import {mapGetters} from "vuex";

export default {
  components: {
    ButtonSave,
  },
  props: {
    companyId: null,
  },
  data() {
    return {
      onControl: false,
      term: {
        name: '',
        type_term: '',
        days: '',
        day_of_month: '',
        days_of_due_date: '',
      },
    }
  },
  async created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async saveTerm() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const data = await CompaniesService.saveTerm({
            company_id: this.companyId,
            name: this.term.name,
            type_term: this.term.type_term,
            days: this.term.days,
            day_of_month: this.term.day_of_month,
            days_of_due_date: this.term.days_of_due_date,
            created_by: this.currentUser.user_id,
          })
          if (data.status === 200) {
            this.close()
            this.$emit('reload')
            this.showSuccessSwal()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
}
</script>

<style scoped>

</style>
