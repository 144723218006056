import { render, staticRenderFns } from "./CreateCustomer.vue?vue&type=template&id=94e05e92&scoped=true"
import script from "./CreateCustomer.vue?vue&type=script&lang=js"
export * from "./CreateCustomer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94e05e92",
  null
  
)

export default component.exports