<template>
  <b-modal
    v-model="ownControl"
    scrollable
    modal-class="modal-primary"
    hide-footer
    title-class="h3 text-white font-weight-bolder"
    title="CUSTOMERS"
    size="caro"
    @hidden="closeModal"
  >
    <div>
      <div class="d-flex justify-content-end mb-1">
        <b-button
          variant="success"
          @click="openCreateCustomer(false)"
        > <feather-icon
          icon="PlusIcon"
          size="15"
          class="text-white mr-1"
        />ADD
        </b-button>
      </div>
      <b-card
        no-body

        class="mb-0"
      >
        <filter-slot

          :filter="filter"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters"
          @reload="$refs['customersList'].refresh()"
        >

          <b-table

            id="customersList"
            slot="table"
            ref="customersList"
            class="m-1"
            sticky-header="50vh"
            no-provider-filtering
            :busy.sync="isBusy"
            :items="search"
            :fields="filteredFields"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="filter"
            show-empty
            small
          >

            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template v-slot:cell(customer)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.print_on_check_as?data.item.display_name_as:data.item.company }}
              </div>

            </template>

            <template v-slot:cell(display_name_as)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.display_name_as }}
              </div>

            </template>
            <template v-slot:cell(mobile)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.mobile }}
              </div>

            </template>
            <template v-slot:cell(phone)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.phone }}
              </div>

            </template>
            <template v-slot:cell(owner)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.owner_first_name }} {{ data.item.owner_middle_name }} {{ data.item.owner_last_name }}
              </div>

            </template>
            <template v-slot:cell(created_by)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                <span>

                  <p>  {{ data.item.created_by + " " }}
                  </p>
                  <p>    {{ data.item.created_at | myGlobalDay }}</p>
                </span>
              </div>

            </template>
            <template v-slot:cell(updated_by)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                <span>

                  <p>  {{ data.item.updated_by + " " }}</p>
                  <p>    {{ data.item.updated_at | myGlobalDay }}</p>
                </span>
              </div>
            </template>
            <template v-slot:cell(actions)="data">
              <div
                class="d-flex   justify-content-start align-items-start "
              >
                <feather-icon
                  title="EDIT"
                  icon="EditIcon"
                  size="20"
                  class="cursor-pointer text-warning"
                  @click="openCreateCustomer(true, data.item.id)"
                />
                <feather-icon
                  title="DELETED"
                  icon="Trash2Icon"
                  size="20"
                  class="cursor-pointer ml-1  text-danger"
                  @click="deleteCustomer(data.item)"
                />
              </div>
            </template>
          </b-table>
        </filter-slot>
      </b-card>
      <create-customer

        v-if="customerOn"
        :id-customer="idCustomer"
        :edit="edit"
        @refresh="$refs['customersList'].refresh()"
        @close="closeCreateCustomer"
      />
    </div>
  </b-modal>
</template>
<script>
import dataFields
from '@/views/management/views/companies-old/dashboard/incomes-module/modals/customers-modal/fields.data'

import dataFilter
from '@/views/management/views/companies-old/dashboard/incomes-module/modals/customers-modal/filters.data'
import { mapGetters } from 'vuex'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import CreateCustomer
from '@/views/management/views/companies-old/dashboard/incomes-module/modals/customers-modal/modals/CreateCustomer.vue'

export default {
  components: {
    CreateCustomer,

  },
  data() {
    return {

      idCustomer: null,
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilter,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      customerOn: false,
      edit: false,

    }
  },
  computed: {
    filteredFields() {
      return this.fields
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),

  },
  created() {
    this.ownControl = true
  },

  methods: {

    openCreateCustomer(edit, idCustomer) {
      this.edit = edit
      this.customerOn = true
      this.idCustomer = idCustomer
    },
    closeCreateCustomer() {
      this.customerOn = false
    },
    async deleteCustomer(data) {
      const params = {
        customer_id: data.id,
        deleted_by: this.currentUser.user_id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await CompaniesService.deleteCustomersById(params)

          this.$refs.customersList.refresh()

          this.showSuccessSwal('Customer has been deleted successfully')
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    closeModal() {
      this.$emit('close')
    },

    resetAllFilters() {
      this.filter.forEach(filter => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.customersList.refresh()
    },
    resetSearch() {
      this.searchInput = ''
      this.$refs.customersList.refresh()
    },

    async search(ctx) {
      try {
        const params = {
          perpage: this.paginate.perPage,
          orderby: 1,
          order: 'desc',
          from: this.filter[0].model,
          to: this.filter[1].model,
          idcompany: Number(this.$route.params.id),
          campo: this.filterPrincipal.model,

        }
        const data = await CompaniesService.getAllManCustomers(params, ctx.currentPage)

        this.items = data.data.data
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style >
@media (min-width: 768px){
  .modal .modal-xmd {
    min-width: 96vw;
    width: 100%;
  }
}
</style>
