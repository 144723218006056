<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    :title="title"
    size="caro"
    modal-class="modal-primary"
    @hidden="closeModal"
  >

    <div>

      <validation-observer ref="form">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="2">

                <b-form-group
                  id="title-1"
                  label="Title"
                  label-for="title-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="title-1"
                    v-model="customer.title"

                    trim
                  />
                </b-form-group>

              </b-col>
              <b-col md="3">
                <b-form-group
                  id="first_name-1"
                  label="First name"
                  label-for="first_name-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="first_name-1"
                    v-model="customer.first_name"

                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="middle_name-1"
                  label="Middle name"
                  label-for="middle_name"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="middle_name"
                    v-model="customer.middle_name"

                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="last_name-1"
                  label="Last name"
                  label-for="last_name"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="last_name"
                    v-model="customer.last_name"

                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="suffix-1"
                  label="Suffix"
                  label-for="suffix"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="suffix"
                    v-model="customer.suffix"

                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="emailss"
              rules="email"
            >
              <b-form-group
                id="emails-1"
                label="Email"
                label-for="emails"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="emails"
                  v-model="customer.emails"
                  :state="errors[0] ? false : null"
                  trim
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              id="company-1"
              label="Company"
              label-for="company"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <b-form-input
                v-if="finishedLoading"
                id="company"
                v-model="customer.company"

                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="phone-1"
                  label="Phone"
                  label-for="phone-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="phone-1"
                    v-model="customer.phone"
                    v-mask="'(###)-###-####'"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="mobile-1"
                  label="Mobile"
                  label-for="mobile-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="mobile-1"
                    v-model="customer.mobile"
                    v-mask="'(###)-###-####'"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="fax-1"
                  label="Fax"
                  label-for="fax-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="fax-1"
                    v-model="customer.fax"
                    v-mask="'(###)-###-####'"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors,valid }"
              name="display_name_as"
              rules="required"
            >
              <b-form-group
                id="display_name_as-1"
                label="Display name as"
                label-for="display_name_as-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="display_name_as-1"
                  v-model="customer.display_name_as"
                  :state="errors[0] ? false : valid ? true : null"
                  trim
                  :disabled="true"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="other-1"
                  label="Other"
                  label-for="other-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="other-1"
                    v-model="customer.other"

                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  id="website-1"
                  label="Website"
                  label-for="website-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="website-1"
                    v-model="customer.website"

                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="3">
                <div class="mb-1">
                  Print on check as
                </div>
              </b-col>
              <b-col md="6">
                <b-form-checkbox
                  v-if="finishedLoading"
                  id="print_on_check_as-1"
                  v-model="customer.print_on_check_as"
                  name="checkbox-1"
                >
                  Use display name
                </b-form-checkbox>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              id="billing_address-1"
              label="Billing Address"
              label-for="billing_address-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <vue-google-autocomplete
                v-if="finishedLoading && companyInformation==='US'"
                id="create_customer_address1"
                ref="create_customer_address1"
                v-model="customer.billing_address"
                classname="input-form form-control fond-white"
                placeholder="Please type your address"
                :country="['us']"
                style="
                            height: 35px !important;"

                @placechanged="getAddressData1"
              />
              <b-form-input
                v-if="finishedLoading && companyInformation==='PE'"
                id="billing_address-1"
                v-model="customer.billing_address"

                trim
              />
            </b-form-group>

          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              id="shipping_address-1"
              label="Shipping Address"
              label-for="shipping_address-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <vue-google-autocomplete
                v-if="finishedLoading && companyInformation==='US'"
                id="create_customer_address2"
                ref="create_customer_address2"
                v-model="customer.shipping_address"
                classname="input-form form-control fond-white"
                placeholder="Please type your address"
                :country="['us']"
                style="
                            height: 35px !important;"

                @placechanged="getAddressData2"
              />

              <b-form-input
                v-if="finishedLoading && companyInformation==='PE'"
                id="billing_city-1"
                v-model="customer.shipping_address"

                trim
              />
            </b-form-group>

          </b-col>

        </b-row>
        <b-row>

          <b-col
            md="6"
            xl="6"
          >
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  id="billing_city-1"
                  label="City/Town"
                  label-for="billing_city-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="billing_city-1"
                    v-model="customer.billing_city"

                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  id="billing_state-1"
                  label="State/Province"
                  label-for="billing_state-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="billing_state-1"
                    v-model="customer.billing_state"

                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  id="shipping_country-1"
                  label="City/Town"
                  label-for="shipping_country-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="shipping_country-1"
                    v-model="customer.shipping_city"

                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  id="shipping_state-1"
                  label="State/Province"
                  label-for="shipping_state-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="shipping_state-1"
                    v-model="customer.shipping_state"

                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              id="billing_zip_code-1"
              label="ZIP code"
              label-for="billing_zip_code-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <b-form-input
                v-if="finishedLoading"
                id="billing_zip_code-1"
                v-model="customer.billing_zip_code"

                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              id="shipping_zip_code-1"
              label="ZIP code"
              label-for="shipping_zip_code-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <b-form-input
                v-if="finishedLoading"
                id="shipping_zip_code-1"
                v-model="customer.shipping_zip_code"

                trim
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              id="note-1"
              label="Notes"
              label-for="note-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <b-form-textarea
                v-if="finishedLoading"
                id="note-1"
                v-model="customer.note"

                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <div class="mb-1">
                  Attachments
                </div>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <div class="mb-1">
                  Maximum size: 20MB
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                xl="12"
              >
                <drag-and-drop
                  v-model="files"
                  source="customer"
                  :files-array="files"
                  :files-to-show="customer.files"
                  @delete-file="deleteFile"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </validation-observer></div>
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="createCustomer"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        SAVE
      </b-button>

    </template>
    <template />
  </b-modal>
</template>

<script>

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import DragAndDrop from '@/views/commons/utilities/DragAndDrop.vue'

import { mapGetters } from 'vuex'
import CompaniesService from '@/views/management/views/companies-old/companies.service'

export default {
  components: {
    VueGoogleAutocomplete,
    DragAndDrop,

  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    idCustomer: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      companyInformation: '',
      spinnerOn: false,
      idDrag: 1,
      files: [],
      ownControl: false,
      finishedLoading: false,
      customer: {
        billing_address: '',
        billing_country: '',
        billing_state: '',
        billing_zip_code: '',
        shipping_address: '',
        shipping_country: '',
        shipping_state: '',
        shipping_zip_code: '',
        display_name_as: '',
        print_on_check_as: true,

      },
      title: '',
      size: 'xmd',
      deletedFiles: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      currentBreakPoint: 'app/currentBreakPoint',

    }),

  },
  watch: {
    'customer.company': function (newVal, oldVal) {
      this.customer.display_name_as = null
      this.customer.display_name_as = newVal
    },
    'customer.first_name': function (newVal, oldVal) {
      if (!this.customer.company) {
        this.customer.display_name_as = null
        this.customer.display_name_as = `${newVal} ${
          this.customer.middle_name ? this.customer.middle_name : ''
        } ${
          this.customer.last_name ? this.customer.last_name : ''
        }`
      }
    },
    'customer.middle_name': function (newVal, oldVal) {
      if (!this.customer.company) {
        this.customer.display_name_as = null
        this.customer.display_name_as = `${
          this.customer.first_name ? this.customer.first_name : ''
        } ${newVal} ${
          this.customer.last_name ? this.customer.last_name : ''
        }`
      }
    },
    'customer.last_name': function (newVal, oldVal) {
      if (!this.customer.company) {
        this.customer.display_name_as = null
        this.customer.display_name_as = `${
          this.customer.first_name ? this.customer.first_name : ''
        } ${
          this.customer.middle_name ? this.customer.middle_name : ''
        } ${newVal}`
      }
    },
    'customer.display_name_as': function (newVal, oldVal) {

    },
    currentBreakPoint(newVal, oldVal) {
      if (newVal === 'xxl') {
        this.size = 'xmd'
      }
      if (oldVal === 'xmd') {
        this.size = 'xxl'
      }
      if (newVal === 'md') {
        this.prueba = 'min-width: 96vw;'
        this.size = 'xxl'
      }
      if (oldVal === 'md') {
        this.size = 'xxl'
      }
    },

  },

  async created() {
    this.putitle()

    this.ownControl = true
    if (this.edit === true) {
      await this.getCustomerById()
    }
    await this.getCompany()
    this.finishedLoading = true
  },

  methods: {
    deleteFile(index, file) {
      this.customer.files.splice(index, 1)
      this.deletedFiles.push(file.id)
    },
    async getCustomerById() {
      try {
        const params = {
          customer_id: this.idCustomer,
        }
        const data = await CompaniesService.getCustomerById(params)

        this.customer = data.data[0]
        if (this.customer.print_on_check_as === 1) {
          this.customer.print_on_check_as = true
        } else {
          this.customer.print_on_check_as = false
        }

        return this.customer
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    putitle() {
      if (this.edit === true) {
        this.title = 'EDIT CUSTOMER'
      } else {
        this.title = 'CREATE CUSTOMER'
      }
    },
    async createCustomer() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const formData = new FormData()
          this.files.forEach(file => {
            formData.append('images[]', file, file.name)
          })
          this.addPreloader()

          const header = {
            customer_id: this.idCustomer,
            idcompany: this.$route.params.id,
            title: this.customer.title ? this.customer.title : this.customer.title = '',
            first_name: this.customer.first_name ? this.customer.first_name : this.customer.first_name = '',
            middle_name: this.customer.middle_name ? this.customer.middle_name : this.customer.middle_name = '',
            last_name: this.customer.last_name ? this.customer.last_name : this.customer.last_name = '',
            suffix: this.customer.suffix ? this.customer.suffix : this.customer.suffix = '',
            emails: this.customer.emails ? this.customer.emails : this.customer.emails = '',
            company: this.customer.company ? this.customer.company : this.customer.company = '',
            display_name_as: this.customer.display_name_as ? this.customer.display_name_as : this.customer.display_name_as = '',
            phone: this.customer.phone ? this.customer.phone : this.customer.title = '',
            mobile: this.customer.mobile ? this.customer.mobile : this.customer.title = '',
            other: this.customer.other ? this.customer.other : this.customer.title = '',
            website: this.customer.website ? this.customer.website : this.customer.title = '',
            fax: this.customer.fax ? this.customer.fax : this.customer.fax = '',
            billing_address: this.customer.billing_address,
            billing_city: this.customer.billing_city ? this.customer.billing_city : this.customer.billing_city = '',
            billing_state: this.customer.billing_state,
            billing_zip_code: this.customer.billing_zip_code,
            billing_country: 'US',
            shipping_address: this.customer.shipping_address,
            shipping_city: this.customer.shipping_city ? this.customer.shipping_city : this.customer.shipping_city = '',
            shipping_state: this.customer.shipping_state,
            shipping_zip_code: this.customer.shipping_zip_code,
            shipping_country: this.customer.shipping_country,
            note: this.customer.note ? this.customer.note : this.customer.note = '',
            print_on_check_as: this.customer.print_on_check_as ? this.customer.print_on_check_as : this.customer.print_on_check_as = false,
            files: this.customer.files ? this.customer.title : this.customer.title = '',
          }

          formData.append('header', JSON.stringify(header))
          formData.append('created_by', this.currentUser.user_id)
          formData.append('updated_by', this.currentUser.user_id)
          const headers = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            // eslint-disable-next-line func-names
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              )
            }.bind(this),
          }
          const response = await amgApi.post(
            'management/companies-old/incomes/save-man-customer',
            formData,
            headers,
          )
          if (response.status === 200) {
            this.uploadFileModal = false
            this.files = []
            this.idDrag++
            this.$emit('refresh')
            this.showSuccessSwal()
            if (this.deletedFiles.length > 0) {
              const data = await CompaniesService.deleteFilesCustomers({
                deleted_files: this.deletedFiles,
              })
            }
            this.closeModal()
          }
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },

    closeModal() {
      this.$emit('close')
    },

    getAddressData1(addressPrincipal1) {
      const mainAddress = addressPrincipal1.street_number
        ? `${addressPrincipal1.street_number} ${addressPrincipal1.route}`
        : addressPrincipal1.route
      this.customer.billing_address = mainAddress || ' '
      this.customer.billing_city = addressPrincipal1.locality ? addressPrincipal1.locality : ' '
      this.customer.billing_state = addressPrincipal1.administrative_area_level_1 ? addressPrincipal1.administrative_area_level_1 : ''
      this.customer.billing_zip_code = addressPrincipal1.postal_code ? addressPrincipal1.postal_code : ''
    },
    getAddressData2(addressPrincipal2) {
      const mainAddress = addressPrincipal2.street_number
        ? `${addressPrincipal2.street_number} ${addressPrincipal2.route}`
        : addressPrincipal2.route
      this.customer.shipping_address = mainAddress || ' '
      this.customer.shipping_city = addressPrincipal2.locality ? addressPrincipal2.locality : ' '
      this.customer.shipping_state = addressPrincipal2.administrative_area_level_1 ? addressPrincipal2.administrative_area_level_1 : ''
      this.customer.shipping_zip_code = addressPrincipal2.postal_code ? addressPrincipal2.postal_code : ''
    },
    async getCompany() {
      try {
        const params = {
          company_id: (this.$route.params.id).toString(),

        }

        const data = await CompaniesService.getCompanyCountryById(params)
        this.companyInformation = data.data[0].country

        return this.companyInformation
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },
}
</script>

<style scoped>

</style>
