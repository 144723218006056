import { amgApi } from '@/service/axios'

class Categories {

  async getCategories(body) {
    try {
      const data = await amgApi.post('/management/categories/man-get-categories', body)
      return data.data
    } catch (error) {
      throw error
    }
  }

  async deleteCategory(body){
    try {
      const data = await amgApi.post('management/categories/man-delete-category', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async saveCategory(body){
    try {
      const data = await amgApi.post('management/categories/man-save-category', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCategory(body) {
    try {
      const data = await amgApi.post('/management/categories/man-get-category', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getAccountTypes() {
    try {
      const data = await amgApi.get('/management/categories/get-account-type')
      return data.data
    } catch (e) {
      throw e
    }
  }

  async getAccountTypesDetail(body) {
    try {
      const data = await amgApi.post('/management/categories/get-account-type-detail', body)
      return data.data
    } catch (e) {
      throw e
    }
  }

  async saveAccountType(body) {
    try {
      const data = await amgApi.post('/management/categories/save-account-type', body)
      return data
    } catch (e) {
      throw e
    }
  }

  async getParentAccounts(body) {
    try {
      const data = await amgApi.post('/management/categories/man-get-parent-accounts', body)
      return data.data
    } catch (e) {
      throw e
    }
  }
  async getParentAccountsTransactions(body) {
    try {
      const data = await amgApi.post('/management/categories/man-get-parent-accounts-transactions', body)
      return data.data
    } catch (e) {
      throw e
    }
  }
}

export default new Categories()
